import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Switch, Checkbox } from 'antd';
import { MESSAGE, PROP } from '../../../utils/constants';
import { showNotification } from '../../../utils/notification';
import InfiniteScroll from 'react-infinite-scroller';
import 'react-quill/dist/quill.snow.css';
import Loading from '../../../components/Loading';
import { changeMaxStudentApi, editStudentApi, getListMasterApi, getListTeacherFromAdminApi, getListUserApi, resetPasswordApi, toggleZcrazyApi, toogleMasterApi, toogleStudentApi, toogleTeacherApi } from '../../../api/admin/study/user';
import { formatDecimal, getAvatarFb, replaceCost } from '../../../utils/common';
import TooltipUser from '../../../components/TooltipUser';
import { EmptyLottie, TitleSuccess } from '../../../components/frame';
import Countdown, { zeroPad } from 'react-countdown-now';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { toggleZvipApi } from '../../../api/admin/zvip';
const ButtonGroup = Button.Group;

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPress: false,
            pageId: 1,
            isLoading: true,
            listUser: [],
            endList: false,
            inputSearch: '',
            userSelect: {},
            listMasterModal: [],
            showModal: false,
            loadingModal: true,
            listTeacherSelect: [],
            endStudentTime: '',
            selectMasterToTeacher: '',
            inputMaxStudent: '',
            isForeverStudentTime: false,
            endStudentTimeEdit: '',
            isForeverStudentTimeEdit: false,
            isFinishEdit: false,
            loadingModal: true,
            studyClassSelect: '',
            selectTab: 1,
            packageNameEdit: '',
            packageStartDateEdit: '',
            packageEndDateEdit: '',
            packageTotalPriceEdit: '',
            packageSuggestPriceEdit: '',
            packagePaidPriceEdit: '',
            zvipExpire: '',
        }
        this.timeoutSearch = ''
    }

    getListUser = (pageId = 1) => {
        getListUserApi(pageId, this.state.inputSearch, this.state.selectTab).then(res => {
            if (res.code == 1) {
                this.setState({
                    isLoading: false,
                    listUser: pageId == 1 ? res.data : [...this.state.listUser, ...res.data],
                    endList: res.data.length < 20,
                    pageId
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    loadMore = () => {
        if (!this.state.endList) {
            this.getListUser(this.state.pageId + 1);
        }
    }

    changeInputSearch = (e) => {
        clearTimeout(this.timeoutSearch)
        const inputSearch = e.target.value;
        this.setState({ inputSearch });
        this.timeoutSearch = setTimeout(() => {
            this.getListUser(1);
        }, 500);
    }

    toogleStudent = (user) => {
        if (!this.state.isPress) {
            this.setState({ isPress: true });
            const { isForeverStudentTime, studyClassSelect } = this.state;
            let endStudentTime = null;
            if (!isForeverStudentTime) {
                endStudentTime = moment(this.state.endStudentTime).format("YYYY-MM-DD HH:mm");
                endStudentTime = endStudentTime == 'Invalid date' ? null : endStudentTime;
            }
            toogleStudentApi(user.UserId, endStudentTime, studyClassSelect).then(res => {
                showNotification(res.message, res.code)
                if (res.code == 1) {
                    this.setState({
                        isPress: false,
                        listUser: this.state.listUser.map(item => {
                            if (item.UserId == user.UserId) {
                                return {
                                    ...item,
                                    IsStudent: res.isStudent,
                                    EndStudentTime: res.isStudent == 0 ? null : this.state.endStudentTime
                                }
                            }
                            return item
                        }),
                        showModal: false
                    })
                }
                else this.setState({ isPress: false });
            }).catch(error => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false });
            })
        }
    }

    toogleTeacher = () => {
        if (!this.state.isPress) {
            this.setState({ isPress: true });
            const { userSelect, selectMasterToTeacher } = this.state;
            toogleTeacherApi(userSelect.UserId, selectMasterToTeacher).then(res => {
                showNotification(res.message, res.code)
                if (res.code == 1) {
                    this.setState({
                        isPress: false,
                        listUser: this.state.listUser.map(item => {
                            if (item.UserId == userSelect.UserId) {
                                return {
                                    ...item,
                                    IsTeacher: res.isTeacher,
                                    MaxStudent: res.isTeacher ? 30 : null
                                }
                            }
                            return item
                        }),
                        showModal: false
                    })
                }
                else this.setState({ isPress: false });
            }).catch(error => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false });
            })
        }
    }

    getListTeacher(userId) {
        getListTeacherFromAdminApi(userId).then(res => {
            if (res.code == 1) {
                this.setState({
                    listTeacherSelect: res.data,
                    loadingModal: false,
                    studyClassSelect: res.data.find(i => i.StudyClassUserId)?.StudyClassId
                })
            }
            else {
                showNotification(res.message, 0);
                this.setState({ isPress: false })
            };
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
            this.setState({ isPress: false })
        })
    }

    changeStudent = (state, item) => {
        if (state) {
            this.getListTeacher(item.UserId);
            this.setState({
                showModal: true,
                modalType: 2,
                userSelect: item,
                studyClassSelect: ''
            })
        }
        else {
            this.toogleStudent(item)
        }
    }

    changeTeacher = (state, item) => {
        if (state) {
            this.setState({
                showModal: true,
                modalType: 1,
                userSelect: item
            })
            getListMasterApi().then(res => {
                if (res.code == 1) {
                    this.setState({
                        listMasterModal: res.listMaster,
                        loadingModal: false
                    })
                }
                else showNotification(MESSAGE.ERROR, 0);
            }).catch(error => {
                showNotification(MESSAGE.ERROR, 0);
            })
        }
        else {
            this.setState({ userSelect: item }, this.toogleTeacher)
        }
    }

    toogleMaster = (userSelect) => {
        if (!this.state.isPress) {
            this.setState({ isPress: true });
            toogleMasterApi(userSelect.UserId).then(res => {
                showNotification(res.message, res.code)
                if (res.code == 1) {
                    this.setState({
                        isPress: false,
                        listUser: this.state.listUser.map(item => {
                            if (item.UserId == userSelect.UserId) {
                                return {
                                    ...item,
                                    IsMaster: res.isMaster,
                                }
                            }
                            return item
                        }),
                    })
                }
                else this.setState({ isPress: false });
            }).catch(error => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false });
            })
        }
    }

    toggleZcrazy = (userSelect) => {
        if (!this.state.isPress) {
            this.setState({ isPress: true });
            toggleZcrazyApi(userSelect.UserId).then(res => {
                showNotification(res.message, res.code)
                if (res.code == 1) {
                    this.setState({
                        isPress: false,
                        listUser: this.state.listUser.map(item => {
                            if (item.UserId == userSelect.UserId) {
                                return {
                                    ...item,
                                    IsZcrazy: res.isZcrazy,
                                }
                            }
                            return item
                        }),
                    })
                }
                else this.setState({ isPress: false });
            }).catch(error => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false });
            })
        }
    }

    toggleZvip = (userSelect, isEnable) => {
        if (!this.state.isPress) {
            if (isEnable && !this.state.zvipExpire) {
                showNotification('Thời hạn Affiliate không được để trống', 0);
                return
            }
            let zvipExpire;
            if (this.state.zvipExpire) {
                zvipExpire = moment(this.state.zvipExpire).format("YYYY-MM-DD HH:mm");
                zvipExpire = zvipExpire == 'Invalid date' ? null : zvipExpire;
            }
            this.setState({ isPress: true });
            toggleZvipApi(userSelect.UserId, zvipExpire, isEnable ? 2 : 0).then(res => {
                showNotification(res.message, res.code)
                if (res.code == 1) {
                    this.setState({
                        isPress: false,
                        listUser: this.state.listUser.map(item => {
                            if (item.UserId == userSelect.UserId) {
                                return {
                                    ...item,
                                    IsZvip: res.isZvip,
                                    Expire: zvipExpire
                                }
                            }
                            return item
                        }),
                        showModal: false
                    })
                }
                else this.setState({ isPress: false });
            }).catch(error => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false });
            })
        }
    }

    resetPassword = () => {
        if (!this.state.isPress) {
            this.setState({ isPress: true });
            resetPasswordApi(this.state.userSelect.UserId).then(res => {
                showNotification(res.message, res.code)
                if (res.code == 1) {
                    this.setState({
                        isPress: false,
                        showModal: false
                    })
                }
                else this.setState({ isPress: false });
            }).catch(error => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false });
            })
        }
    }

    renderModalType = () => {
        const {
            isPress,
            modalType,
            loadingModal,
            listMasterModal,
            userSelect,
            endStudentTime,
            selectMasterToTeacher,
            inputMaxStudent,
            isForeverStudentTime,
            endStudentTimeEdit,
            isForeverStudentTimeEdit,
            listTeacherSelect,
            studyClassSelect,
            isFinishEdit,
            packageEndDateEdit,
            packageNameEdit,
            packagePaidPriceEdit,
            packageStartDateEdit,
            packageSuggestPriceEdit,
            packageTotalPriceEdit,
            zvipExpire
        } = this.state;
        switch (modalType) {
            case 1:
                return (
                    <>
                        {loadingModal ?
                            <Loading small />
                            :
                            <>
                                <div className='mgt mgb'>
                                    <TitleSuccess>Chọn Master</TitleSuccess>
                                </div>
                                {listMasterModal.map((item, key) =>
                                    <div
                                        className='box-primary flex align-center pointer'
                                        key={key}
                                        onClick={() => this.setState({
                                            selectMasterToTeacher: item.UserId
                                        })}
                                    >
                                        <div className='flex-row flex-1 align-center'>
                                            <TooltipUser userInfo={item} />
                                            <p className='mgl-2'>{item.FullName}</p>
                                        </div>
                                        <Checkbox
                                            checked={selectMasterToTeacher == item.UserId}
                                        />
                                    </div>
                                )}
                                {listMasterModal.length > 0 ?
                                    <div className='item-center mgt-2'>
                                        <Button
                                            className='bg-success mgt'
                                            onClick={this.toogleTeacher}
                                            loading={isPress}
                                        >
                                            Xác nhận
                                        </Button>
                                    </div>
                                    :
                                    <EmptyLottie title='Không có Master được bật' />
                                }
                            </>
                        }
                    </>
                )
            case 2:
                return (
                    <div className='item-center mgt mgb'>
                        <TitleSuccess>Chọn ngày hết hạn</TitleSuccess>
                        <img
                            src={getAvatarFb(userSelect.FacebookId)}
                            className='avatar-guest mgt'
                        />
                        <p>{userSelect.FullName}</p>
                        <div className='flex align-center mgt-2'>
                            <p className='mgr-2'>Đóng học phí toàn phần:</p>
                            <Switch
                                checked={isForeverStudentTime}
                                onChange={(state) => this.setState({ isForeverStudentTime: state > 0 })}
                            />
                        </div>
                        {!isForeverStudentTime &&
                            <>
                                <p className='mgt-2 text-left'>Hạn học tập hiện tại:</p>
                                <div className='flex align-center date-study-picker'>
                                    <DatePicker
                                        selected={moment(endStudentTime).isValid() ? moment(endStudentTime).toDate() : ''}
                                        onChange={endStudentTime => this.setState({ endStudentTime })}
                                        timeFormat="HH:mm"
                                        timeIntervals={60}
                                        timeCaption="time"
                                        dateFormat="d-M-yyyy H:mm"
                                        showTimeSelect
                                        minDate={Date.now()}
                                        placeholderText='Chọn ngày'
                                    />
                                </div>
                            </>
                        }
                        <p className='mgt-2 text-left'>Chọn giáo viên:</p>
                        {listTeacherSelect.map((item, key) =>
                            <div
                                className='box-primary flex align-center pointer'
                                key={key}
                                onClick={() => this.setState({ studyClassSelect: item.StudyClassId })}
                            >
                                <div className='flex-row flex-1 align-center'>
                                    <TooltipUser userInfo={item} />
                                    <p className='mgl-2'>{item.FullName}</p>
                                </div>
                                <Checkbox
                                    checked={item.StudyClassId == studyClassSelect}
                                />
                            </div>
                        )}
                        <div className='item-center mgt-2'>
                            <Button
                                className='bg-success mgt'
                                onClick={() => this.toogleStudent(this.state.userSelect)}
                                loading={isPress}
                            >
                                Xác nhận
                            </Button>
                        </div>
                    </div>
                )
            case 3:
                return (
                    <div className='item-center mgt mgb'>
                        <TitleSuccess>Chọn số lượng học viên</TitleSuccess>
                        <img
                            src={getAvatarFb(userSelect.FacebookId)}
                            className='avatar-guest mgt'
                        />
                        <p>{userSelect.FullName}</p>
                        <div className='flex align-center date-study-picker'>
                            <input
                                type='number'
                                className='form-control'
                                value={inputMaxStudent}
                                onChange={e => this.setState({ inputMaxStudent: e.target.value })}
                                placeholder='Nhập số lượng học viên'
                            />
                        </div>
                        <div className='item-center mgt-2'>
                            <Button
                                className='bg-success mgt'
                                onClick={this.changeMaxStudent}
                                loading={isPress}
                            >
                                Xác nhận
                            </Button>
                        </div>
                    </div>
                )
            case 4:
                return (
                    <>
                        <TitleSuccess>Sửa thuộc tính</TitleSuccess>
                        <div className='flex align-center mgt-2'>
                            <p className='mgr-2'>Đã tốt nghiệp/tạm ngưng:</p>
                            <Switch
                                checked={isFinishEdit}
                                onChange={(state) => this.setState({ isFinishEdit: state > 0 })}
                            />
                        </div>
                        <div className='flex align-center mgt-2'>
                            <p className='mgr-2'>Đóng học phí toàn phần:</p>
                            <Switch
                                checked={isForeverStudentTimeEdit}
                                onChange={(state) => this.setState({ isForeverStudentTimeEdit: state > 0 })}
                            />
                        </div>
                        {!isForeverStudentTimeEdit &&
                            <>
                                <p className='mgt'>Hạn học tập hiện tại:</p>
                                <div className='flex align-center date-study-picker mgt'>
                                    <DatePicker
                                        selected={moment(endStudentTimeEdit).isValid() ? moment(endStudentTimeEdit).toDate() : ''}
                                        onChange={endStudentTimeEdit => this.setState({ endStudentTimeEdit })}
                                        timeFormat="HH:mm"
                                        timeIntervals={60}
                                        timeCaption="time"
                                        dateFormat="d-M-yyyy H:mm"
                                        showTimeSelect
                                        minDate={Date.now()}
                                        placeholderText='Chọn ngày'
                                    />
                                </div>
                            </>
                        }
                        <div className='box-primary mgt'>
                            <div className='package-container'>
                                <p className='text-left mgr-2'>Gói học:</p>
                                <input
                                    className='package-input'
                                    placeholder='Nhập gọi học'
                                    value={packageNameEdit}
                                    onChange={e => this.setState({ packageNameEdit: e.target.value })}
                                />
                            </div>
                            <div className='package-container'>
                                <p className='text-left mgt-2 mgr-2'>Ngày bắt đầu:</p>
                                <div className='flex flex-1 align-center date-study-picker mgt' style={{ maxWidth: '45vh' }}>
                                    <DatePicker
                                        selected={moment(packageStartDateEdit).isValid() ? moment(packageStartDateEdit).toDate() : ''}
                                        onChange={packageStartDateEdit => this.setState({ packageStartDateEdit })}
                                        timeFormat="HH:mm"
                                        timeIntervals={60}
                                        timeCaption="time"
                                        dateFormat="d-M-yyyy H:mm"
                                        showTimeSelect
                                        placeholderText='Chọn ngày'
                                    />
                                </div>
                            </div>
                            <div className='package-container'>
                                <p className='text-left mgt-2 mgr-2'>Ngày kết thúc</p>
                                <div className='flex flex-1 align-center date-study-picker mgt' style={{ maxWidth: '45vh' }}>
                                    <DatePicker
                                        selected={moment(packageEndDateEdit).isValid() ? moment(packageEndDateEdit).toDate() : ''}
                                        onChange={packageEndDateEdit => this.setState({ packageEndDateEdit })}
                                        timeFormat="HH:mm"
                                        timeIntervals={60}
                                        timeCaption="time"
                                        dateFormat="d-M-yyyy H:mm"
                                        showTimeSelect
                                        placeholderText='Chọn ngày'
                                    />
                                </div>
                            </div>
                            <div className='package-container'>
                                <p className='text-left mgr-2'>Tổng học phí:</p>
                                <input
                                    className='package-input'
                                    placeholder='Nhập giá (vnđ)'
                                    value={packageTotalPriceEdit}
                                    onChange={e => this.setState({ packageTotalPriceEdit: formatDecimal(e.target.value) })}
                                />
                            </div>
                            <div className='package-container'>
                                <p className='text-left mgr-2'>Đề xuất trả góp:</p>
                                <input
                                    className='package-input'
                                    placeholder='Nhập giá (vnđ)'
                                    value={packageSuggestPriceEdit}
                                    onChange={e => this.setState({ packageSuggestPriceEdit: formatDecimal(e.target.value) })}
                                />
                            </div>
                            <div className='package-container'>
                                <p className='text-left mgr-2'>Đã đóng:</p>
                                <input
                                    className='package-input'
                                    placeholder='Nhập giá (vnđ)'
                                    value={packagePaidPriceEdit}
                                    onChange={e => this.setState({ packagePaidPriceEdit: formatDecimal(e.target.value) })}
                                />
                            </div>
                        </div>
                        <p className='mgt-2 text-left'>Chọn giáo viên:</p>
                        {listTeacherSelect.map((item, key) =>
                            <div
                                className='box-primary flex align-center pointer'
                                key={key}
                                onClick={() => this.setState({ studyClassSelect: item.StudyClassId })}
                            >
                                <div className='flex-row flex-1 align-center'>
                                    <TooltipUser userInfo={item} />
                                    <p className='mgl-2'>{item.FullName}</p>
                                </div>
                                <Checkbox
                                    checked={item.StudyClassId == studyClassSelect}
                                />
                            </div>
                        )}
                        <div className='item-center mgt-4'>
                            <Button
                                className='bg-success mgt'
                                onClick={this.editStudent}
                                loading={isPress}
                            >
                                Xác nhận
                            </Button>
                        </div>
                    </>
                )
            case 5:
                return (
                    <div className='item-center mgt mgb'>
                        <TitleSuccess>Reset mật khẩu</TitleSuccess>
                        <img
                            src={getAvatarFb(userSelect.FacebookId)}
                            className='avatar-guest mgt'
                        />
                        <p>{userSelect.FullName}</p>
                        <p>Mật khẩu sẽ được đặt lại là: 123456</p>
                        <div className='item-center mgt-2'>
                            <Button
                                className='bg-success mgt'
                                onClick={this.resetPassword}
                                loading={isPress}
                            >
                                Xác nhận
                            </Button>
                        </div>
                    </div>
                )
            case 6:
                return (
                    <div className='item-center mgt mgb'>
                        <img
                            src={getAvatarFb(userSelect.FacebookId)}
                            className='avatar-guest mgt'
                        />
                        <p>{userSelect.FullName}</p>
                        <TitleSuccess>Thời hạn Affiliate</TitleSuccess><p></p>
                        <div className='package-container'>
                            <div className='flex flex-1 align-center date-study-picker mgt'>
                                <DatePicker
                                    selected={moment(zvipExpire).isValid() ? moment(zvipExpire).toDate() : ''}
                                    onChange={zvipExpire => this.setState({ zvipExpire })}
                                    timeFormat="HH:mm"
                                    timeIntervals={60}
                                    timeCaption="time"
                                    dateFormat="d-M-yyyy H:mm"
                                    showTimeSelect
                                    placeholderText='Chọn ngày'
                                />
                            </div>
                        </div>
                        <div className='item-center mgt-2'>
                            <Button
                                className='bg-success mgt'
                                onClick={() => this.toggleZvip(userSelect, true)}
                                loading={isPress}
                            >
                                Xác nhận
                            </Button>
                        </div>
                    </div>
                )
        }
        return null;
    }

    editStudent = () => {
        if (!this.state.isPress) {
            this.setState({ isPress: true });
            const { endStudentTimeEdit, isForeverStudentTimeEdit, userSelect, isFinishEdit, studyClassSelect, packageEndDateEdit, packageNameEdit, packagePaidPriceEdit, packageStartDateEdit, packageSuggestPriceEdit, packageTotalPriceEdit } = this.state;
            let endStudentTime = null;
            let packageEndDate = null;
            let packageStartDate = null;
            if (!isForeverStudentTimeEdit) {
                endStudentTime = moment(endStudentTimeEdit).format("YYYY-MM-DD HH:mm");
                endStudentTime = endStudentTime == 'Invalid date' ? null : endStudentTime;
            }
            if (packageStartDateEdit) {
                packageStartDate = moment(packageStartDateEdit).format("YYYY-MM-DD HH:mm");
                packageStartDate = packageStartDate == 'Invalid date' ? null : packageStartDate;
            }
            if (packageEndDateEdit) {
                packageEndDate = moment(packageEndDateEdit).format("YYYY-MM-DD HH:mm");
                packageEndDate = packageEndDate == 'Invalid date' ? null : packageEndDate;
            }
            editStudentApi(
                userSelect.UserId,
                endStudentTime,
                isFinishEdit ? 2 : 0,
                studyClassSelect,
                packageNameEdit,
                packageStartDate,
                packageEndDate,
                replaceCost(packageTotalPriceEdit),
                replaceCost(packageSuggestPriceEdit),
                replaceCost(packagePaidPriceEdit)
            ).then(res => {
                showNotification(res.message, res.code)
                if (res.code == 1) {
                    this.setState({
                        isPress: false,
                        listUser: this.state.listUser.map(item => {
                            if (item.UserId == userSelect.UserId) {
                                return {
                                    ...item,
                                    EndStudentTime: endStudentTime === null ? '0000-00-00 00:00:00' : endStudentTime,
                                    PackageName: packageNameEdit,
                                    PackageStartDate: packageStartDate,
                                    PackageEndDate: packageEndDate,
                                    PackageTotalPrice: replaceCost(packageTotalPriceEdit),
                                    PackageSuggestPrice: replaceCost(packageSuggestPriceEdit),
                                    PackagePaidPrice: replaceCost(packagePaidPriceEdit)
                                }
                            }
                            return item
                        }),
                        showModal: false
                    })
                }
                else this.setState({ isPress: false });
            }).catch(error => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false });
            })
        }
    }

    changeMaxStudent = () => {
        if (!this.state.isPress) {
            const { userSelect, inputMaxStudent } = this.state;
            this.setState({ isPress: true })
            if (inputMaxStudent > 0) {
                changeMaxStudentApi(userSelect.UserId, inputMaxStudent).then(res => {
                    showNotification(res.message, res.code)
                    if (res.code == 1) {
                        this.setState({
                            isPress: false,
                            listUser: this.state.listUser.map(item => {
                                if (item.UserId == userSelect.UserId) {
                                    return {
                                        ...item,
                                        MaxStudent: inputMaxStudent
                                    }
                                }
                                return item
                            }),
                            showModal: false
                        })
                    }
                    else this.setState({ isPress: false });
                }).catch(error => {
                    showNotification(MESSAGE.ERROR, 0);
                    this.setState({ isPress: false });
                })
            }
            else showNotification('Số lượng học viên phải lớn hơn 0');
        }
    }

    componentDidMount() {
        this.getListUser();
    }

    buttonGroupClass = (selectTab) => {
        if (selectTab == this.state.selectTab) return 'button-group-home bg-success';
        return 'button-group-home';
    }

    render() {
        const { listUser, isLoading, endList, inputSearch, showModal } = this.state;
        return (
            <>
                {isLoading ?
                    <Loading small />
                    :
                    <>
                        <ButtonGroup className='item-center flex mgb' style={{ width: '100%' }}>
                            <Button onClick={() => this.setState({ selectTab: 1 }, this.getListUser)} className={this.buttonGroupClass(1)}>Đang học</Button>
                            <Button onClick={() => this.setState({ selectTab: 2 }, this.getListUser)} className={this.buttonGroupClass(2)}>Chưa từng học</Button>
                            <Button onClick={() => this.setState({ selectTab: 3 }, this.getListUser)} className={this.buttonGroupClass(3)}>Đã tốt nghiệp</Button>
                        </ButtonGroup>
                        <input
                            type='text'
                            className='form-control'
                            value={inputSearch}
                            onChange={this.changeInputSearch}
                            placeholder='Nhập họ tên hoặc số điện thoại'
                        />
                        <InfiniteScroll
                            loadMore={this.loadMore}
                            hasMore={!endList}
                            loader={<Loading key={0} small />}
                            threshold={100}
                            className='mgt-2'
                        >
                            {listUser.map((item, key) =>
                                <div className='row box-primary relative' key={key}>
                                    <div className='col-md-6 col-sm-6 col-xs-12 flex align-center'>
                                        <TooltipUser userInfo={item} avatarClass='avatar-guest-small mgr' />
                                        <div>
                                            <p className='mgb-0'>{item.FullName} - {item.PhoneNumber}</p>
                                            {item.EndStudentTime && item.EndStudentTime != '0000-00-00 00:00:00' &&
                                                <p className='mgb-0'>
                                                    <Countdown
                                                        date={moment(item.EndStudentTime, "YYYY-MM-DD HH:mm").unix() * 1000}
                                                        renderer={({ days, hours, minutes, seconds }) => (<span>{days}d {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>)}
                                                    />
                                                </p>
                                            }
                                            <p>
                                            <span>{item.Email} - </span>
                                            <span
                                                className='pointer'
                                                onClick={() => this.setState({
                                                    showModal: true,
                                                    modalType: 5,
                                                    userSelect: item,
                                                })}
                                            >
                                                <u>Reset mật khẩu</u>
                                            </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-sm-6 col-xs-12 flex'>
                                        <div className='row-user-admin-switch'>
                                            {item.IsStudent > 0 ?
                                                <p
                                                    className='pointer'
                                                    onClick={() => {
                                                        this.getListTeacher(item.UserId);
                                                        this.setState({
                                                            showModal: true,
                                                            modalType: 4,
                                                            userSelect: item,
                                                            endStudentTimeEdit: moment(item.EndStudentTime, "YYYY-MM-DD HH:mm").unix() * 1000,
                                                            isForeverStudentTimeEdit: item.EndStudentTime == "0000-00-00 00:00:00",
                                                            isFinishEdit: item.IsFinish == 2,
                                                            studyClassSelect: item.StudyClassId,
                                                            packageNameEdit: item.PackageName,
                                                            packageEndDateEdit: moment(item.PackageEndDate, "YYYY-MM-DD HH:mm").unix() * 1000,
                                                            packageStartDateEdit: moment(item.PackageStartDate, "YYYY-MM-DD HH:mm").unix() * 1000,
                                                            packageSuggestPriceEdit: item.PackageSuggestPrice > 0 ? formatDecimal(item.PackageSuggestPrice) : '',
                                                            packagePaidPriceEdit: item.PackagePaidPrice > 0 ? formatDecimal(item.PackagePaidPrice) : '',
                                                            packageTotalPriceEdit: item.PackageTotalPrice > 0 ? formatDecimal(item.PackageTotalPrice) : '',
                                                        })
                                                    }}
                                                >
                                                    <u>Học viên</u>
                                                </p>
                                                :
                                                <p>Học viên</p>
                                            }
                                            <Switch
                                                checked={item.IsStudent > 0}
                                                onChange={(state) => this.changeStudent(state, item)}
                                            />
                                        </div>
                                        <div className='row-user-admin-switch'>
                                            {item.IsTeacher > 0 ?
                                                <p
                                                    className='pointer'
                                                    onClick={() => this.setState({
                                                        showModal: true,
                                                        modalType: 3,
                                                        userSelect: item,
                                                        inputMaxStudent: item.MaxStudent
                                                    })}
                                                >
                                                    <u>Giáo viên({item.MaxStudent})</u>
                                                </p>
                                                :
                                                <p>Giáo viên</p>
                                            }
                                            <Switch
                                                checked={item.IsTeacher > 0}
                                                onChange={(state) => this.changeTeacher(state, item)}
                                            />
                                        </div>
                                        <div className='row-user-admin-switch'>
                                            <p>Master</p>
                                            <Switch
                                                checked={item.IsMaster > 0}
                                                onChange={() => this.toogleMaster(item)}
                                            />
                                        </div>
                                        <div className='row-user-admin-switch'>
                                            <p>Z VIP</p>
                                            <Switch
                                                checked={item.IsZcrazy > 0}
                                                onChange={() => this.toggleZcrazy(item)}
                                            />
                                        </div>
                                        <div className='row-user-admin-switch'>
                                            {item.IsZvip > 0 ?
                                                <p
                                                    className='pointer'
                                                    onClick={() => {
                                                        this.setState({
                                                            showModal: true,
                                                            modalType: 6,
                                                            userSelect: item,
                                                            zvipExpire: item.Expire
                                                        })
                                                    }}
                                                >
                                                    <u>Z AFF</u>
                                                </p>
                                                :
                                                <p>Z AFF</p>
                                            }
                                            <Switch
                                                checked={item.IsZvip > 0}
                                                onChange={(state) => {
                                                    if (state) {
                                                        this.setState({
                                                            showModal: true,
                                                            modalType: 6,
                                                            userSelect: item,
                                                            zvipExpire: item.Expire
                                                        })
                                                    }
                                                    else this.toggleZvip(item, false)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </InfiniteScroll>
                        <Modal
                            {...PROP.MODAL}
                            visible={showModal}
                            onCancel={() => this.setState({ showModal: false })}
                            centered
                        >
                            {this.renderModalType()}
                        </Modal>
                    </>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser }
}

export default connect(mapStateToProps)(Main)