const INITAL_STATE = {
    listChatAll: [],
    listChatClub: [],
    listPeople: [],
    listChatPeople: [],
    loadingChatAll: true,
    loadingChatClub: true,
    loadingPeople: true,
    loadingChatPeople: true,
    endList: false,
    selectItem: 0,
    showChat: false,
    showPeople: false,
    userChatInfo: {}
};

export default (state = INITAL_STATE, action) => {
    switch (action.type) {
        case 'INIT_GET_LIST_CHAT':
            return { ...state,
                selectItem: action.selectItem,
                listChatPeople: []
            }
        case 'SUCCESS_GET_LIST_CHAT_ALL':
            return { ...state,
                listChatAll: action.listChat.reverse(),
                loadingChatAll: false,
                selectItem: 0
            }
        case 'SUCCESS_GET_LIST_CHAT_CLUB':
            return { ...state,
                listChatClub: action.listChat.reverse(),
                loadingChatClub: false,
                selectItem: 1
            }
        case 'SUCCESS_GET_LIST_CHAT_PEOPLE':
            return { ...state,
                listPeople: action.listPeople,
                loadingPeople: false,
                selectItem: 2
            }
        case 'SUCCESS_GET_LIST_CHAT_PEOPLE_MESSAGE':
            return { ...state,
                listChatPeople: action.listChat.reverse(),
                loadingChatPeople: false,
                selectItem: 3
            }
        case 'SHOW_HIDE_CHAT':
            return { ...state,
                showChat: action.showChat !== undefined ? action.showChat : state.showChat,
                showPeople: action.showPeople !== undefined ? action.showPeople: state.showPeople,
                userChatInfo: action.userChatInfo !== undefined ? action.userChatInfo : state.userChatInfo
            }
        case 'ACTION_CHAT_PEOPLE':
            return { ...state,
                listChatPeople: [
                    {
                        ChatUserMessageId: action.data.chatUserMessageId,
                        CrDateTime: 'Vừa xong',
                        Message: action.data.message,
                        IsImage: action.data.isImage,
                        UserReceive: action.data.userIdRevice,
                        IsZcrazy: action.data.isZcrazy,
                        UserSend: action.data.userId,
                        Reply: action.data.reply
                    },
                    ...state.listChatPeople,
                ]
            }
        case 'ACTION_CHAT_ALL':
            return { ...state,
                listChatAll: [
                    {
                        ChatId: action.data.chatId,
                        FacebookId: action.data.facebookId,
                        FullName: action.data.fullName,
                        UserId: action.data.userId,
                        IsZcrazy: action.data.isZcrazy,
                        CrDateTime: 'Vừa xong',
                        Message: action.data.message,
                        IsImage: action.data.isImage,
                        Reply: action.data.reply,
                        Type:  action.data.type,
                    },
                    ...state.listChatAll,
                ]
            }
        case 'ACTION_CHAT_CLUB':
            return { ...state,
                listChatClub: [
                    {
                        ChatClubId: action.data.chatClubId,
                        FacebookId: action.data.facebookId,
                        FullName: action.data.fullName,
                        UserId: action.data.userId,
                        CrDateTime: 'Vừa xong',
                        Message: action.data.message,
                        IsZcrazy: action.data.isZcrazy,
                        IsImage: action.data.isImage,
                        Reply: action.data.reply
                    },
                    ...state.listChatClub,
                ]
            }
        case 'ACTION_CHAT_STUDY':
            return { ...state,
                selectItem: action.selectItem
            }
        case 'FALSE_GET_LIST_CHAT':
            return { ...state };
        default:
            return state;
    }
};