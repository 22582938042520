import React, { Component } from 'react';
import { Button, Modal } from 'antd';
import { confirmTransactionApi, confirmUpgradeApi, getListConfigZvipApi, getListLogAdminApi, getListTransactionApi, getListUpgradeApi, rejectTransactionApi, saveConfigZvipApi } from '../../api/admin/zvip';
import TooltipUser from '../../components/TooltipUser';
import { formatDecimal, convertNonUnicode, replaceCost } from '../../utils/common';
import { MESSAGE, PROP } from '../../utils/constants';
import { showNotification } from '../../utils/notification';
import { getListBankApi } from '../../api/aff';
import ButtonStatus from '../../components/affiliate/ButtonStatus';
import { TitleSuccess } from '../../components/frame';
const ButtonGroup = Button.Group;
const SELECT_TAB = {
    UPGRADE: 1,
    TRANSACTION: 2,
    RANK: 3,
    LOG: 4,
    CONFIG: 5
}
const MODAL_TYPE = {
    CONFRIM_UPGRADE: 1,
    CONFIRM_TRANSACTION: 2,
    REJECT_TRANSACTION: 3,
}

class Support extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectTab: SELECT_TAB.UPGRADE,
            listUpgrade: [],
            listTransaction: [],
            showModal: false,
            modalType: 1,
            selectUpgrade: {},
            selectTransaction: {},
            isPress: false,
            banks: [],
            logs: [],
            config: {},
            inputBaseBalanceZcrazy: '',
            inputBaseBalanceAff: '',
            inputBonus: ''
        }
    }

    buttonGroupClass = (selectTab) => {
        if (selectTab == this.state.selectTab) return 'button-group-home bg-success';
        return 'button-group-home';
    }

    getListUpgrade = () => {
        getListUpgradeApi().then(res => {
            if (res.code == 1) {
                this.setState({ listUpgrade: res.data })
            }
        })
    }

    getListBank = () => {
        getListBankApi().then(res => {
            this.setState({ banks: res.data })
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    confirmUpgrade = () => {
        if (!this.state.isPress) {
            this.setState({ isPress: true });
            confirmUpgradeApi(this.state.selectUpgrade?.ZvipRequestId).then(res => {
                showNotification(res.message, res.code)
                this.setState({ isPress: false })
                if (res.code == 1) {
                    this.setState({ showModal: false })
                    this.getListUpgrade();
                }
            }).catch(error => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false })
            })
        }
    }

    confirmTransaction = () => {
        if (!this.state.isPress) {
            this.setState({ isPress: true });
            confirmTransactionApi(this.state.selectTransaction?.ZvipTransactionId).then(res => {
                showNotification(res.message, res.code)
                this.setState({ isPress: false })
                if (res.code == 1) {
                    this.setState({ showModal: false })
                    this.getListTransaction();
                }
            }).catch(error => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false })
            })
        }
    }

    rejectTransaction = () => {
        if (!this.state.isPress) {
            this.setState({ isPress: true });
            rejectTransactionApi(this.state.selectTransaction?.ZvipTransactionId).then(res => {
                showNotification(res.message, res.code)
                this.setState({ isPress: false })
                if (res.code == 1) {
                    this.setState({ showModal: false })
                    this.getListTransaction();
                }
            }).catch(error => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false })
            })
        }
    }

    saveConfig = () =>{

    }

    renderTab = () => {
        const { listUpgrade, selectTab, listTransaction, banks, logs } = this.state;
        switch (selectTab) {
            case SELECT_TAB.UPGRADE:
                return (
                    <>
                        {listUpgrade.map(item =>
                            <div className='box-primary row' key={item.ZvipRequestId}>
                                <div className='col-md-4 col-sm-12 col-xs-12 flex align-center'>
                                    <TooltipUser
                                        userInfo={item}
                                        avatarClass='avatar-guest-large'
                                    />
                                    <div className='flex-column mgl'>
                                        <p className='text-large bold'>Nâng cấp {item.UpgradeType == 1 ? 'Z-VIP' : 'Affiliate Pro'}</p>
                                        <p className='bold'>{item.FullName} - {item.PhoneNumber}</p>
                                        <p className='text-time mgb-0'>Online: {item.UpdateDateTime}</p>
                                        <p className='text-time mgb-0'>Khởi tạo: {item.CrDateTime}</p>
                                    </div>
                                </div>
                                <div className='col-md-4 col-sm-12 col-xs-12 flex-column item-center'>
                                    <div className='button-money-aff bg-success'>
                                        <p className='text-large bold mgb-0'>+ {formatDecimal(item.Balance)}</p>
                                    </div>
                                    <p className='mgt text-time'>{item.CreateTime}</p>
                                </div>
                                <div className='col-md-4 col-sm-12 col-xs-12 flex item-center pointer'>
                                    <div
                                        className='button-money-aff'
                                        style={{ width: '20vh' }}
                                        onClick={() => {
                                            this.setState({
                                                showModal: true,
                                                modalType: MODAL_TYPE.CONFRIM_UPGRADE,
                                                selectUpgrade: item,
                                            })
                                        }}
                                    >
                                        <p className='text-large bold mgb-0'>Duyệt</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )
            case SELECT_TAB.TRANSACTION:
                return (
                    <>
                        {listTransaction.map(item => {
                            const isWithdraw = item.Type == 2;
                            const bankSelect = banks.find(i => i.BankId === item.BankId)
                            return (
                                <div className='box-primary row' key={item.ZvipTransactionId}>
                                    <div className='col-md-4 col-sm-12 col-xs-12 flex align-center'>
                                        <TooltipUser
                                            userInfo={item}
                                            avatarClass='avatar-guest-large'
                                        />
                                        <div className='flex-column mgl'>
                                            <p className='text-large bold'>{isWithdraw ? 'Rút tiền' : 'Nạp tiền'}</p>
                                            <p className='bold'>{item.FullName} - {item.PhoneNumber}</p>
                                            <p className='text-time mgb-0'>Online: {item.UpdateDateTime}</p>
                                            <p className='text-time mgb-0'>Khởi tạo: {item.CrDateTime}</p>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-sm-12 col-xs-12 flex-column item-center'>
                                        <div>
                                            <div className={`button-money-aff ${isWithdraw ? 'bg-warning' : 'bg-success'}`}>
                                                <p className='text-large bold mgb-0'>{isWithdraw ? '-' : '+'} {formatDecimal(item.Balance)} đ</p>
                                            </div>
                                            <p className='mgt text-time'>{item.CreateTime}</p>
                                            {isWithdraw &&
                                                <div className='mgt'>
                                                    <p className='text-left mgb-0'>Ngân hàng: {item.BankName}</p>
                                                    <p className='text-left mgb-0'>STK: {item.BankStk}</p>
                                                    <p className='text-left mgb-0'>Chi nhánh: {item.BankBranch}</p>
                                                </div>
                                            }
                                        </div>
                                        {isWithdraw &&
                                            <div className='mgt'>
                                                <img
                                                    src={`https://img.vietqr.io/image/${bankSelect?.BankCode}-${item.BankStk}-compact2.png?amount=${item.Balance}&addInfo=Zcity chuyen khoan`}
                                                    className='aff-transaction-qr-img'
                                                />
                                            </div>
                                        }
                                    </div>
                                    <div className='col-md-4 col-sm-12 col-xs-12 flex-column item-center pointer'>
                                        <div
                                            className='button-money-aff'
                                            style={{ width: '20vh' }}
                                            onClick={() => {
                                                this.setState({
                                                    showModal: true,
                                                    modalType: MODAL_TYPE.CONFIRM_TRANSACTION,
                                                    selectTransaction: item,
                                                })
                                            }}
                                        >
                                            <p className='text-large bold mgb-0'>Duyệt</p>
                                        </div>
                                        {isWithdraw &&
                                            <div
                                                className='button-money-aff bg-danger mgt-2'
                                                style={{ width: '20vh' }}
                                                onClick={() => {
                                                    this.setState({
                                                        showModal: true,
                                                        modalType: MODAL_TYPE.REJECT_TRANSACTION,
                                                        selectTransaction: item,
                                                    })
                                                }}
                                            >
                                                <p className='text-large bold mgb-0'>Từ chối</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </>
                )
            case SELECT_TAB.LOG:
                return (
                    <>
                        {logs.map((item) =>
                            <div className='box-affiliate mgb row' key={item.ZvipTransactionLogId}>
                                <div className='flex col-md-9 col-sm-6 col-xs-12 align-center'>
                                    <TooltipUser userInfo={item} avatarClass='avatar-guest-large' />
                                    <div className='mgl-2'>
                                        <p>{item.Comment}</p>
                                        <p>Số dư: {formatDecimal(item.Balance)} đ</p>
                                        <p className='text-time'>{item.CrDateTime}</p>
                                    </div>
                                </div>
                                <div className='flex col-md-3 col-sm-6 col-xs-12 align-center flex-end'>
                                    <ButtonStatus item={item} />
                                </div>
                            </div>
                        )}
                    </>
                )
            case SELECT_TAB.CONFIG:
                return (
                    <>
                        <TitleSuccess>Giá Z-Vip</TitleSuccess>
                        <div className='item-center'>
                            <input
                                className='form-control col-md-4'
                                value={formatDecimal(this.state.inputBaseBalanceZcrazy)}
                                onChange={e => this.setState({ inputBaseBalanceZcrazy: e.target.value })}
                            />
                        </div>
                        <TitleSuccess>Giá Affiliate Pro</TitleSuccess>
                        <div className='item-center'>
                            <input
                                className='form-control col-md-4'
                                value={formatDecimal(this.state.inputBaseBalanceAff)}
                                onChange={e => this.setState({ inputBaseBalanceAff: e.target.value })}
                            />
                        </div>
                        <TitleSuccess>Giá giảm sau khi nhập mã</TitleSuccess>
                        <div className='item-center'>
                            <input
                                className='form-control col-md-4'
                                value={formatDecimal(this.state.inputBonus)}
                                onChange={e => this.setState({ inputBonus: e.target.value })}
                            />
                        </div>
                        <div className='item-center'>
                            <Button className='bg-success mgt-3' onClick={this.saveConfig}>Lưu</Button>
                        </div>
                    </>
                )
        }
        return null;
    }

    renderModalType = () => {
        const { modalType, selectUpgrade, isPress, selectTransaction } = this.state
        switch (modalType) {
            case MODAL_TYPE.CONFRIM_UPGRADE:
                return (
                    <div className='flex-column item-center'>
                        <p className='text-huge text-center mgt-2'>Xác nhận duyệt Nâng cấp {selectUpgrade.UpgradeType == 1 ? 'Z-VIP' : 'Affiliate Pro'} ?</p>
                        <TooltipUser
                            userInfo={selectUpgrade}
                            avatarClass='avatar-guest-large mgt-2'
                        />
                        <div className='button-money-aff bg-success mgt-2'>
                            <p className='text-large bold mgb-0'>+ {formatDecimal(selectUpgrade.Balance)}</p>
                        </div>
                        <Button
                            className='bg-success mgt-3'
                            onClick={this.confirmUpgrade}
                            loading={isPress}
                        >
                            Xác nhận
                        </Button>
                    </div>
                )
            case MODAL_TYPE.CONFIRM_TRANSACTION:
                return (
                    <div className='flex-column item-center'>
                        <p className='text-huge text-center mgt-2'>Xác nhận duyệt {selectTransaction.Type == 1 ? 'Nạp tiền' : 'Rút tiền'} ?</p>
                        <TooltipUser
                            userInfo={selectTransaction}
                            avatarClass='avatar-guest-large mgt-2'
                        />
                        <div className='button-money-aff bg-success mgt-2'>
                            <p className='text-large bold mgb-0'>{formatDecimal(selectTransaction.Balance)}</p>
                        </div>
                        <Button
                            className='bg-success mgt-3'
                            onClick={this.confirmTransaction}
                            loading={isPress}
                        >
                            Xác nhận
                        </Button>
                    </div>
                )
            case MODAL_TYPE.REJECT_TRANSACTION:
                return (
                    <div className='flex-column item-center'>
                        <p className='text-huge text-center mgt-2'>Xác nhận hoàn tiền ?</p>
                        <TooltipUser
                            userInfo={selectTransaction}
                            avatarClass='avatar-guest-large mgt-2'
                        />
                        <div className='button-money-aff bg-danger mgt-2'>
                            <p className='text-large bold mgb-0'>{formatDecimal(selectTransaction.Balance)}</p>
                        </div>
                        <Button
                            className='bg-danger mgt-3'
                            onClick={this.rejectTransaction}
                            loading={isPress}
                        >
                            Xác nhận
                        </Button>
                    </div>
                )
        }
        return null;
    }

    getListTransaction = () => {
        getListTransactionApi().then(res => {
            if (res.code == 1) {
                this.setState({ listTransaction: res.data })
                this.getListBank();
            }
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    getListLog = () => {
        getListLogAdminApi().then(res => {
            if (res.code == 1) {
                this.setState({ logs: res.data })
            }
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    getListConfig = () => {
        getListConfigZvipApi().then(res => {
            if (res.code == 1) {
                this.setState({ 
                    inputBaseBalanceAff: res.data.BaseBalanceAff,
                    inputBaseBalanceZcrazy: res.data.BaseBalanceZcrazy,
                    inputBonus: res.data.Bonus,
                })
            }
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    saveConfig = () =>{
        saveConfigZvipApi({
            BaseBalanceAff: replaceCost(this.state.inputBaseBalanceAff),
            BaseBalanceZcrazy: replaceCost(this.state.inputBaseBalanceZcrazy),
            Bonus: replaceCost(this.state.inputBonus)
        }).then(res => {
            showNotification(res.message, res.code);
            if(res.code == 1){
                this.getListConfig()
            }
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    componentDidMount() {
        this.getListUpgrade()
    }

    render() {
        return (
            <>
                <ButtonGroup className='item-center flex mgt mgb' style={{ width: '100%' }}>
                    <Button onClick={() => this.setState({ selectTab: 1 }, this.getListUpgrade)} className={this.buttonGroupClass(1)}>Xét duyệt Affiliate Pro</Button>
                    <Button onClick={() => this.setState({ selectTab: 2 }, this.getListTransaction)} className={this.buttonGroupClass(2)}>Xét duyệt Nạp/Rút</Button>
                    <Button onClick={() => this.setState({ selectTab: 3 })} className={this.buttonGroupClass(3)}>Bảng xếp hạng</Button>
                    <Button onClick={() => this.setState({ selectTab: 4 }, this.getListLog)} className={this.buttonGroupClass(4)}>Tất cả giao dịch</Button>
                    <Button onClick={() => this.setState({ selectTab: 5 }, this.getListConfig)} className={this.buttonGroupClass(5)}>Set giá</Button>
                </ButtonGroup>
                {this.renderTab()}
                <Modal
                    {...PROP.MODAL}
                    visible={this.state.showModal}
                    onCancel={() => this.setState({ showModal: false })}
                    centered
                >
                    {this.state.showModal && this.renderModalType()}
                </Modal>
            </>
        )
    }
}

export default Support;