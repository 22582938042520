import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changePoint, updateDataUser } from '../../actions/home/';
import {
    getListChatAll,
    getListChatClub,
    getListPeople,
    getListChatPeople,
    actionChatAll,
    actionChatClub,
    actionChatPeople,
    showHideChat,
    actionChatStudy
} from '../../actions/chat';
import { sendChatPeopleApi } from '../../api/chat/chatUser';
import { sendChatAllApi } from '../../api/chat/chatAll';
import { sendChatClubApi } from '../../api/chat/chatClub';
import { MESSAGE, URI_PATH, PROP, COLOR } from '../../utils/constants';
import { getAvatarFb, getImage64 } from '../../utils/common';
import { showNotification, showNotificationBonus, showNotificationMessage } from '../../utils/notification';
import Loading from '../../components/Loading';
import Emoji from './Emoji';
import Icon from 'react-fontawesome';
import { Button, Tooltip } from 'antd';
import FsLightbox from 'fslightbox-react';
import { socket } from '../realtime/ReadSocketEvent';
import TooltipUser from '../../components/TooltipUser';
import StudyChat from './StudyChat';
import Checked from '../frame/Checked';
const ButtonGroup = Button.Group;

class ChatNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            isSend: false,
            showEmoji: false,
            showPreviewImage: false,
            imagePreview: '',
            replyData: {}
        };
    }

    sendRequest = (message, isImage = '') => {
        let chatType = this.props.selectItem;
        let dataUser = this.props.dataUser;
        let replyData = this.state.replyData;
        switch (chatType) {
            case 0: // chat all
                sendChatAllApi(message, isImage, replyData.ChatId).then(res => {
                    if (res.code == 1) {
                        if (res.Balance > 0) {
                            showNotificationBonus(res.Balance, -1, 2);
                            this.props.changePoint([null, -res.Balance]);
                        }
                        if (isImage != '') {
                            message = res.imageUrl;
                            isImage = 2;
                        }
                        this.refs.chatList.scrollTo(0, document.querySelector(".chat-list").scrollHeight);
                        this.setState({
                            isSend: false,
                            showEmoji: false,
                            message: '',
                            replyData: {}
                        });
                        //socket
                        let dataSend = {
                            chatId: res.chatId,
                            message: message,
                            isImage: isImage,
                            userId: res.userId,
                            facebookId: res.facebookId,
                            fullName: dataUser.FullName,
                            isZcrazy: dataUser.IsZcrazy,
                            reply: replyData,
                            type: dataUser.UserId == 1 ? 2 : 1,
                            crDateTime: 'Vừa xong'
                        };
                        socket.emit('send_chat_all', dataSend);
                        this.props.actionChatAll(dataSend);
                    }
                    else {
                        showNotification(res.message, res.code);
                        this.setState({
                            isSend: false,
                            showEmoji: false,
                        });
                    }
                }).catch(e => {
                    showNotification(MESSAGE.ERROR, -1);
                    this.setState({
                        isSend: false,
                        showEmoji: false,
                    });
                });
                break;
            case 1: //chat Club
                sendChatClubApi(message, isImage, replyData.ChatClubId).then(res => {
                    if (res.code == 1) {
                        if (isImage != '') {
                            message = res.imageUrl;
                            isImage = 2;
                        }
                        this.refs.chatList.scrollTo(0, document.querySelector(".chat-list").scrollHeight);
                        this.setState({
                            isSend: false,
                            showEmoji: false,
                            message: '',
                            replyData: {}
                        });
                        //socket
                        let dataSend = {
                            chatId: res.chatClubId,
                            message: message,
                            isImage: isImage,
                            clubId: dataUser.ClubId,
                            userId: res.userId,
                            facebookId: res.facebookId,
                            fullName: dataUser.FullName,
                            isZcrazy: dataUser.IsZcrazy,
                            reply: replyData,
                            crDateTime: 'Vừa xong'
                        };
                        socket.emit('send_chat_club', dataSend);
                        this.props.actionChatClub(dataSend);
                    }
                    else {
                        showNotification(res.message, res.code);
                        this.setState({
                            isSend: false,
                            showEmoji: false,
                        });
                    }
                }).catch(e => {
                    showNotification(MESSAGE.ERROR, -1);
                    this.setState({
                        isSend: false,
                        showEmoji: false,
                    });
                });
                break;
            case 3: //chat people
                let userChatInfo = this.props.userChatInfo;
                sendChatPeopleApi(message, userChatInfo.UserId, isImage, replyData.ChatUserMessageId).then(res => {
                    if (res.code == 1) {
                        if (isImage != '') {
                            message = res.imageUrl;
                            isImage = 2;
                        }
                        this.refs.chatList.scrollTo(0, document.querySelector(".chat-list").scrollHeight);
                        this.setState({
                            isSend: false,
                            showEmoji: false,
                            message: '',
                            replyData: {}
                        });
                        //socket
                        let dataSend = {
                            chatUserMessageId: res.chatUserMessageId,
                            message: message,
                            isImage: isImage,
                            userId: res.userId,
                            userReceive: userChatInfo.UserId,
                            facebookId: dataUser.FacebookId,
                            fullName: dataUser.FullName,
                            isZcrazy: dataUser.IsZcrazy,
                            reply: replyData,
                            crDateTime: 'Vừa xong'
                        };
                        if (res.isSendNotification) socket.emit('send_chat_people', dataSend);
                        this.props.actionChatPeople(dataSend);
                        //
                    }
                    else {
                        showNotification(res.message, res.code);
                        this.setState({
                            isSend: false,
                            showEmoji: false,
                        });
                    }
                }).catch(e => {
                    showNotification(MESSAGE.ERROR, -1);
                    this.setState({
                        isSend: false,
                        showEmoji: false,
                    });
                });
                break;
        }
    }

    send = () => {
        let message = this.state.message.trim();
        if (!this.state.isSend && message != '') {
            this.setState({ isSend: true });
            this.sendRequest(message);
        }
    }

    uploadImage = event => {
        let file = event.target.files[0];
        getImage64(file, image64 => {
            this.sendRequest('', image64);
        });
        this.refs.inputImage.value = '';
    }

    readSocketEvent = () => {
        let _this = this;
        socket.on('receive_chat_all', (data) => {
            let { showChat, selectItem, dataUser } = this.props;
            if (selectItem == 0) {
                this.props.actionChatAll(data);
            }
        });
        socket.on('receive_chat_club_' + this.props.dataUser.ClubId, (data) => {
            let { showChat, selectItem, dataUser } = this.props;
            if (selectItem == 1) {
                this.props.actionChatClub(data);
            }
        });
        socket.on('receive_chat_people', (data) => {
            if (data.userId == this.props.userChatInfo.UserId) this.props.actionChatPeople(data);
            showNotificationMessage(data, function (instance, toast) {
                _this.props.getListChatPeople(data.userId);
                _this.props.showHideChat(true, false, {
                    UserId: data.userId,
                    FacebookId: data.facebookId,
                    FullName: data.fullName,
                    IsZcrazy: data.isZcrazy
                });
                instance.hide({
                    transitionOut: 'fadeOutUp',
                }, toast, '');
            });
        });
    }

    pickEmoji = e => {
        let emoji = e.target.firstChild.nodeValue;
        this.setState({ message: this.state.message + emoji })
    }

    showChat = () => {
        let selectItem = this.props.selectItem;
        this.props.showHideChat(true, selectItem == 2 || selectItem == 4);
        switch (selectItem) {
            case 0:
                this.props.getListChatAll();
                break;
            case 1:
                this.props.getListChatClub();
                break;
        }
    }

    componentDidMount() {
        this.readSocketEvent();
        this.props.getListChatAll();
    }

    getListChat = selectItem => {
        if (selectItem != this.props.selectItem) {
            if (selectItem == 4) { // study
                this.props.actionChatStudy(4)
            }
            else if (selectItem == 2) { // show people chat
                this.props.getListPeople();
            }
            else {  // chat all, club
                if (selectItem == 1) {
                    if (!this.props.dataUser.ClubId) return showNotification('Bạn chưa vào Club', -1);
                    this.props.getListChatClub();
                }
                else this.props.getListChatAll();
            }
        }
    }

    getListChatPeople = (userInfo) => {
        this.props.getListChatPeople(userInfo.UserId);
        this.props.showHideChat(true, false, userInfo);
    }

    buttonGroupClass = (selectItem) => {
        const isTeacher = this.props.dataUser.IsTeacher;
        if (selectItem === this.props.selectItem) return `button-group-chat bg-success ${(isTeacher && 'width25')}`;
        return `button-group-chat ${(isTeacher && 'width25')}`;
    }

    renderMessageAdmin = (data) => {
        if (!data?.UserRegisterInfo) {
            return (
                <p className='mgb-0 white'>{data?.Message}</p>
            )
        }
        const messageArr = data?.Message.split(data?.UserRegisterInfo.FullName);
        return (
            <p className='mgb-0 white'>
                <span>{messageArr[0]}</span>
                <TooltipUser userInfo={data?.UserRegisterInfo} customComponent={
                    <span className='success bold'>{data?.UserRegisterInfo.FullName}</span>
                } />
                <span> {messageArr[1]}</span>
            </p>
        )
    }

    renderChat = () => {
        let {
            selectItem,
            loadingChatAll,
            loadingChatClub,
            loadingPeople,
            loadingChatPeople,
            listChatAll,
            listChatClub,
            listPeople,
            listChatPeople,
            userChatInfo,
            dataUser
        } = this.props;
        let dataChat;
        if (
            selectItem == 0 && loadingChatAll ||
            selectItem == 1 && loadingChatClub ||
            selectItem == 2 && loadingPeople ||
            selectItem == 3 && loadingChatPeople
        ) return <Loading />;
        if (selectItem == 2) { // list People
            return (
                listPeople.map((item, key) => {
                    return (
                        <div
                            key={key}
                            className={`flex-row chat-people-list ${(!item.IsRead && 'bg-success')}`}
                            onClick={() => this.getListChatPeople(item)}
                        >
                            <TooltipUser userInfo={item} />
                            <div className='flex-column chat-people-info'>
                                <span>{item.FullName}<Checked data={item} /></span>
                                <span className='placeholder'>{item.CrDateTime}</span>
                            </div>
                        </div>
                    )
                })
            )
        }
        else if (selectItem == 4 || selectItem == 5) { //study
            return <StudyChat />
        }
        else { // chat All, chat Club
            switch (selectItem) {
                case 0:
                    dataChat = listChatAll;
                    break;
                case 1:
                    dataChat = listChatClub;
                    break;
                case 3:
                    dataChat = listChatPeople;
                    break;
            }
            return (
                dataChat.map((item, key) => {
                    if (item.UserId == dataUser.UserId || item.UserSend == dataUser.UserId) {
                        return (
                            <Tooltip
                                {...PROP.TOOLTIP_HOME}
                                overlayClassName='black-tooltip'
                                title={
                                    <div className='item-center'>
                                        <p className='bold'>{item.CrDateTime}</p>
                                    </div>
                                }
                                key={key}
                            >
                                <div className='user-message'>
                                    <div className='user-message-type'>
                                        {typeof item.Reply === 'object' && Object.keys(item.Reply).length > 0 &&
                                            <div className='box-primary mgb flex-column'>
                                                <div className='flex-row align-center mgb'>
                                                    <img src={getAvatarFb(item.Reply.FacebookId)} className='avatar-message' />
                                                    <p className='mgl'>{item.Reply.FullName}<Checked data={item.Reply} /></p>
                                                </div>
                                                {item.Reply.IsImage == 2 ?
                                                    <div className='item-center'>
                                                        <img
                                                            src={`${URI_PATH}${item.Reply.Message}`}
                                                            className='image-chat pointer'
                                                            onClick={() => this.setState({
                                                                showPreviewImage: true,
                                                                imagePreview: `${URI_PATH}${item.Reply.Message}`
                                                            })}
                                                        />
                                                    </div>
                                                    :
                                                    <p className='mgb-0'>{item.Reply.Message}</p>
                                                }
                                            </div>
                                        }
                                        {item.IsImage == 2 ?
                                            <div className='item-center'>
                                                <img
                                                    src={`${URI_PATH}${item.Message}`}
                                                    className='image-chat pointer'
                                                    onClick={() => this.setState({
                                                        showPreviewImage: true,
                                                        imagePreview: `${URI_PATH}${item.Message}`
                                                    })}
                                                />
                                            </div>
                                            :
                                            <p className='mgb-0'>{item.Message}</p>
                                        }
                                    </div>
                                </div>
                            </Tooltip>
                        )
                    }
                    else {
                        if (item.Type == 2) {
                            return (
                                <div className='div-guest-message' key={key}>
                                    <img
                                        className={'avatar-message'}
                                        key={Math.random()}
                                        alt='avatar-user'
                                        src={require('../../assets/image/logo-z.png')}
                                    />
                                    <Tooltip
                                        {...PROP.TOOLTIP_HOME}
                                        overlayClassName='black-tooltip'
                                        title={
                                            <div className='item-center'>
                                                <p className='bold'>{item.CrDateTime}</p>
                                            </div>
                                        }
                                    >
                                        <div className="guest-message shadow-pulse" style={{ backgroundColor: COLOR.COLOR_WARNING }} >
                                            <div className='guest-message-type'>
                                                {this.renderMessageAdmin(item)}
                                            </div>
                                        </div>
                                    </Tooltip>
                                </div>
                            )
                        }
                        return (
                            <div className='div-guest-message' key={key}>
                                <TooltipUser userInfo={selectItem == 3 ? userChatInfo : item} avatarClass='avatar-message' />
                                <Tooltip
                                    {...PROP.TOOLTIP_HOME}
                                    overlayClassName='black-tooltip'
                                    title={
                                        <div className='item-center'>
                                            <p className='bold'>{item.CrDateTime}</p>
                                            <Button
                                                className='btn-reply-chat'
                                                onClick={() => this.setState({ replyData: selectItem == 3 ? Object.assign(item, userChatInfo) : item })}
                                            >
                                                Trả lời
                                            </Button>
                                        </div>
                                    }
                                >
                                    <div className="guest-message">
                                        <div className='guest-message-type'>
                                            {typeof item.Reply === 'object' && Object.keys(item.Reply).length > 0 &&
                                                <div className='box-primary mgb flex-column'>
                                                    <div className='flex-row align-center mgb'>
                                                        <img src={getAvatarFb(item.Reply.FacebookId)} className='avatar-message' />
                                                        <p className='mgl'>{item.Reply.FullName}<Checked data={item.Reply} /></p>
                                                    </div>
                                                    {item.Reply.IsImage == 2 ?
                                                        <div className='item-center'>
                                                            <img
                                                                src={`${URI_PATH}${item.Reply.Message}`}
                                                                className='image-chat pointer'
                                                                onClick={() => this.setState({
                                                                    showPreviewImage: true,
                                                                    imagePreview: `${URI_PATH}${item.Reply.Message}`
                                                                })}
                                                            />
                                                        </div>
                                                        :
                                                        <p className='mgb-0'>{item.Reply.Message}</p>
                                                    }
                                                </div>
                                            }
                                            {item.IsImage == 2 ?
                                                <div className='item-center'>
                                                    <img
                                                        src={`${URI_PATH}${item.Message}`}
                                                        className='image-chat pointer'
                                                        onClick={() => this.setState({
                                                            showPreviewImage: true,
                                                            imagePreview: `${URI_PATH}${item.Message}`
                                                        })}
                                                    />
                                                </div>
                                                :
                                                <p className='mgb-0'>{item.Message}</p>
                                            }
                                        </div>
                                    </div>
                                </Tooltip>
                            </div>
                        )
                    }
                })
            )
        }
    }

    render() {
        let { selectItem, showChat, showPeople, userChatInfo, dataUser } = this.props;
        let { showEmoji, message, showPreviewImage, imagePreview, replyData } = this.state;
        return (
            <div className='chat-container'>
                <div className='chat-content bg-transparent'>
                    <ButtonGroup className='item-center relative flex' style={{ height: '6.2vh' }}>
                        <Button
                            className={this.buttonGroupClass(0)}
                            onClick={() => this.getListChat(0)}
                        >
                            City {dataUser.CountChatAll > 0 && `(${dataUser.CountChatAll})`}
                        </Button>
                        <Button
                            className={this.buttonGroupClass(1)}
                            onClick={() => this.getListChat(1)}
                        >
                            Club {dataUser.CountChatClub > 0 && `(${dataUser.CountChatClub})`}
                        </Button>
                        <Button
                            className={this.buttonGroupClass(2)}
                            onClick={() => this.getListChat(2)}
                        >
                            Inbox
                        </Button>
                        <Button
                            className={this.buttonGroupClass(4)}
                            onClick={() => this.getListChat(4)}
                        >
                            Study
                        </Button>
                    </ButtonGroup>
                    {userChatInfo && selectItem == 3 &&
                        <div className='flex-row item-center bg-success'>
                            <img
                                src={getAvatarFb(userChatInfo.FacebookId)}
                                style={{ width: '5vh', height: '5vh', borderRadius: '50%' }}
                            />
                            <div className='flex-column mgl'>
                                <p>{userChatInfo.FullName}</p>
                            </div>
                        </div>
                    }
                    <div
                        className='chat-list'
                        ref='chatList'
                        style={{ flexDirection: showPeople ? 'column' : 'column-reverse' }}
                    >
                        {this.renderChat()}
                    </div>
                    {!showPeople &&
                        <div>
                            {Object.keys(replyData).length > 0 && //reply
                                <div className='box-primary relative'>
                                    <div>Trả lời: {replyData.FullName}</div>
                                    {replyData.IsImage == 2 ?
                                        <div>
                                            <img
                                                src={`${URI_PATH}${replyData.Message}`}
                                                className='image-reply mgt'
                                            />
                                        </div>
                                        :
                                        <p className='mgt mgb-0 text-small'>{replyData.Message}</p>
                                    }
                                    <img
                                        src={require('../../assets/image/delete.png')}
                                        className='delete-reply-chat'
                                        onClick={() => this.setState({ replyData: {} })}
                                    />
                                </div>
                            }
                            <div className='input-chat-container bg-primary'>
                                {showEmoji && <Emoji onPick={this.pickEmoji} />}
                                <textarea
                                    className='input-chat'
                                    placeholder='Nhập nội dung tán ngẫu'
                                    // placeholder={selectItem == 0 ? 'Quảng cáo (1,000 $[z]/tin)' : 'Nhập nội dung'}
                                    value={message}
                                    onChange={e => this.setState({ message: e.target.value })}
                                    onFocus={() => this.setState({ showEmoji: false })}
                                    onKeyDown={e => {
                                        if (e.keyCode === 13) {
                                            this.send();
                                            e.preventDefault();
                                        }
                                    }}
                                />
                                <div className='icon-chat-container item-center' style={{ right: '10.2vh' }}>
                                    <Icon
                                        name='smile-o'
                                        className={'icon-chat-select ' + (showEmoji ? 'success' : null)}
                                        onClick={() => this.setState({ showEmoji: !showEmoji })}
                                    />
                                </div>
                                <div className='icon-chat-container item-center' style={{ right: '6.2vh' }}>
                                    <input
                                        type='file'
                                        hidden='hidden'
                                        ref='inputImage'
                                        onChange={this.uploadImage}
                                    />
                                    <Icon
                                        name='picture-o'
                                        className='icon-chat-select'
                                        onClick={() => this.refs.inputImage.click()}
                                    />
                                </div>
                                <div className='icon-chat-container item-center' style={{ right: '1.7vh' }}>
                                    <Icon
                                        name='paper-plane'
                                        className='icon-chat-select'
                                        onClick={this.send}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {showPreviewImage &&
                    <FsLightbox
                        toggler={showPreviewImage}
                        sources={[imagePreview]}
                        disableLocalStorage={true}
                        openOnMount={true}
                        onClose={() => this.setState({
                            showPreviewImage: false,
                            imagePreview: ''
                        })}
                        key={0}
                        type='image'
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        listChatAll,
        listChatClub,
        listPeople,
        listChatPeople,
        loadingChatAll,
        loadingChatClub,
        loadingChatPeople,
        loadingUser,
        endList,
        selectItem,
        showChat,
        showPeople,
        userChatInfo
    } = state.getListChat;
    const { dataUser } = state.getUserData;
    return {
        listChatAll,
        listChatClub,
        listPeople,
        listChatPeople,
        loadingChatAll,
        loadingChatClub,
        loadingChatPeople,
        loadingUser,
        endList,
        selectItem,
        showChat,
        showPeople,
        userChatInfo,
        dataUser
    };
}

export default connect(mapStateToProps, {
    getListChatAll,
    getListChatClub,
    getListPeople,
    getListChatPeople,
    actionChatAll,
    actionChatClub,
    actionChatPeople,
    showHideChat,
    changePoint,
    updateDataUser,
    actionChatStudy
})(ChatNav);